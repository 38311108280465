<template>
  <div class="landing">
    <!-- <section class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <img
              src="@/assets/img/landing/hero.png"
              class="img-fluid"
              alt="hero image"
            />
          </div>
        </div>
      </div>
    </section> -->

  </div>
</template>

<script>
export default {
  name: "landing",
  mounted() {
    this.globalDeviceCheck();
  },
};
</script>
<style lang="scss" scoped>
.landing {
  min-height: 100vh;
}
</style>